<script>
import Layout from "../../layouts/main.vue";

import * as signalR from "@microsoft/signalr";
import { setupConnection, linkify } from "./js/from-server";
import Swal from "sweetalert2";

import Profiles from "./_Profiles2.vue";
import Messages from "./_Messages.vue";
import Donate from "./_Donate2.vue";

import Hls from 'hls.js';
import axios from 'axios';

var wsconn = new signalR.HubConnectionBuilder()
  .withUrl(process.env.VUE_APP_SIGNALR_URL, signalR.HttpTransportType.WebSockets)
  .configureLogging(signalR.LogLevel.None).build();

export default {
  data() {
    return {
      data: {
        id: '',
        episode: {
        }
      },
      pinnedMessage: null,
      cssPlayButtonClass: 'ri-play-line',
      cssMuteButtonClass: 'ri-volume-mute-line',
      classMuteOn: 'ri-volume-mute-line',
      classMuteOff: 'ri-volume-up-line',
      wsconnSetup: false,
      moderatorConnectionId: null,
      hslLoaded: false,
      loadingStreamPanel: true,
      streamActive: false,
      streamConfigAllowChat: true,
      streamConfigAllowDonations: false,
      listenersCounter: 0,
      newMessageCounter: 0,
      previousWidth: 0,
      previousHeight: 0,
    };
  },
  props: {
  },
  components: {
    Layout,
    Profiles,
    Messages,
    Donate,
  },
  mounted() {
    this.ifNeedWsconnSetup();
    this.loadingEpisode();
    this.signalRCreate();
  },
  methods: {
    loadingEpisode() {
      axios.get('/api/episode/simple/' + this.$route.params.id, { withCredentials: true })
        .then((response) => {
          this.data = response.data;
        })
        .catch(function () {
        });
    },
    loadingStream() {
      var vm = this;
      var playerControl = document.getElementById('player');
      playerControl.onloadeddata = function () {
        console.log("Play stream");
        try {
          playerControl.play();
          vm.cssPlayButtonClass = 'ri-pause-line';
          vm.hslLoaded = true;
          vm.loadingStreamPanel = false;
          console.log("Play success");

          Swal.fire({
            position: "top-end",
            icon: "info",
            title: "Turn on the sound, open the chat and enjoy the video!",
            showConfirmButton: true,
            confirmButtonText: 'Unmute',
            showDenyButton: true,
            denyButtonText: 'Chat',
            showCloseButton: true,
            customClass: {
              confirmButton: "btn btn-primary",
              denyButton: "btn btn-primary ms-2"
            },
            buttonsStyling: false,
            timer: 7500
          }).then((result) => {
            if (result.isConfirmed) {
              vm.muteStream();
            } else if (result.isDenied) {
              document.getElementById('streamChatOpenButton').click();
            }
          });

          var panel = document.getElementById('playerPanel');
          panel.classList.remove('d-none');
        }
        catch (error) {
          console.error("Play error", error)
          vm.cssPlayButtonClass = 'ri-play-line';
          vm.loadingStreamPanel = false;
        }
      };

      var streamSrc = process.env.VUE_APP_MEDIAMTX_HLS_URL + this.$route.params.session + '/index.m3u8';
      console.log("Stream HLS url", streamSrc);
      console.log("Hls.isSupported()", Hls.isSupported());
      if (Hls.isSupported()) {
        console.log('Setup HLS instance...');
        var hls = new Hls();
        hls.loadSource(streamSrc);
        hls.attachMedia(playerControl);
        hls.on(Hls.Events.ERROR, (event, data) => {
          const { type, details, fatal } = data;
          console.warn(`HLS error: type=${type}, details=${details}, fatal=${fatal}`);

          if (fatal) {
              switch (type) {
                  case Hls.ErrorTypes.NETWORK_ERROR:
                      console.error('Network error occurred. Attempting to recover...');
                      //hls.startLoad();
                      this.stopStream();
                      break;
                  case Hls.ErrorTypes.MEDIA_ERROR:
                      console.error('Media error occurred. Attempting to recover...');
                      hls.recoverMediaError();
                      break;
                  default:
                      console.error('Fatal error. Destroying HLS instance.');
                      //hls.destroy();
                      this.stopStream();
                      break;
              }
          }
        });
        hls.on(Hls.Events.LEVEL_SWITCHED, (event, data) => {
          console.log("Level switched: ", data);
          const currentLevel = hls.levels[data.level];
          const { width, height } = currentLevel;
          if (width && height) {
            playerControl.width = width;
            playerControl.height = height;

            console.log(`Video size updated to: ${width}x${height}`);
          }
        });
        
        playerControl.load();
      }
      else if (playerControl.canPlayType('application/vnd.apple.mpegurl')) {
        console.log('Set SRC for audio...');
        playerControl.src = streamSrc;
      }
    },
    checkVideoSize() {
      var playerControl = document.getElementById('player');
      const currentWidth = playerControl.videoWidth;
      const currentHeight = playerControl.videoHeight;

      if (currentWidth !== this.previousWidth || currentHeight !== this.previousHeight) {
          console.log(`New video size. Width: ${currentWidth}, Height: ${currentHeight}`);

          this.previousWidth = currentWidth;
          this.previousHeight = currentHeight;

          playerControl.width = currentWidth;
          playerControl.height = currentHeight;
      }
    },
    signalRCreate() {
      var vm = this;
      wsconn.start().then(() => {
        console.log("SignalR: Connected");

        var id = this.$route.params.session;
        wsconn.invoke("Guest", id)
          .then(() => {
            console.log("Connect as guest.");
            vm.streamActive = true;
            vm.$refs.pnlMessages.connected = true;
            vm.loadingStream();
          })
          .catch((error) => {
            console.error("Connect as guest: ", error);
            vm.streamActive = false;
            vm.loadingStreamPanel = false;
          });

      }).catch((error) => {
        console.error(error);
        vm.streamActive = false;
        vm.loadingStreamPanel = false;
      });
    },
    ifNeedWsconnSetup() {
      console.log("SignalR current state", wsconn.state);
      if (this.wsconnSetup === false) {
        console.log("Need to setup wsconn...");
        setupConnection(wsconn, this);
        this.wsconnSetup = true;
      }
    },
    sendMessage(form) {
      var isGuest = true;
      wsconn.invoke("SendMessage", form.message, isGuest).catch((error) => {
        console.error(error);
      });
    },
    chatOpen(value) {
      if(value)
        this.newMessageCounter = 0;
    },
    playStream() {      
      if(this.hslLoaded) {
        console.log('Current time', this.$refs.player.currentTime);
        console.log('Duration', this.$refs.player.duration);

        if (this.cssPlayButtonClass === 'ri-play-line') {
          if (this.$refs.player.duration > 0)
            this.$refs.player.currentTime = this.$refs.player.duration;
          this.$refs.player.play();
          this.cssPlayButtonClass = 'ri-pause-line';
        }
        else {
          this.$refs.player.pause();
          this.cssPlayButtonClass = 'ri-play-line';
        }
      }
    },
    stopStream() {
      this.streamActive = false;
      this.cssPlayButtonClass = 'ri-play-line';
      this.$refs.player.src = '';
      this.$refs.pnlMessages.connected = false;
      wsconn.stop();

      var panel = document.getElementById('playerPanel');
      panel.classList.add('d-none');
    },
    updateProfiles(list) {
      void list;
    },
    sendSystemMessageToChat(message, attach = false) {
      var time = new Date().toLocaleTimeString();
        var m = {
          align: 'left',
          name: '',
          image: '',
          message: message,
          time: time,
          system: true,
        }
        this.$refs.pnlMessages.addMessage(m, attach, true);
    },
    muteStream() {
      if (this.cssMuteButtonClass === this.classMuteOn) {
        this.$refs.player.muted = false;
        this.cssMuteButtonClass = this.classMuteOff;
      }
      else {
        this.$refs.player.muted = true;
        this.cssMuteButtonClass = this.classMuteOn;
      }
    },
    getLinkifiedPinnedMessage() {
      return linkify(this.pinnedMessage.message.value);
    },
  },
  computed: {
    getEpisodeId: function () {
      if (this.data && this.data.episode && this.data.episode.id)
        return this.data.episode.id;
      return this.$route.params.id;
    },
  }
};
</script>

<template>
  <Layout>

    <div class="text-center mb-3" v-if="loadingStreamPanel">
      <a href="javascript:void(0);" class="text-primary"><i class="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> Loading stream... </a>
    </div>

    <div class="team-list list-view-filter mb-4">
      <div class="card team-box">
        <div class="card-body">
          <div class="row align-items-center team-row">
            <div class="col team-settings">
              <div class="row align-items-center">
                
              </div>
            </div>
            <div class="col-lg-3 col">
              <div class="team-profile-img">
                <div class="avatar-lg img-thumbnail rounded-circle">
                  <img src="data.episode.urlAvatar" alt="" class="img-fluid d-block rounded-circle">
                </div>
                <div class="team-content">
                  <a href="#" class="d-block">
                    <h5 class="fs-16 mb-1">{{ data.episode.title }}</h5>
                  </a>
                  <p class="text-muted mb-0">{{ data.episode.short }}</p>
                </div>
              </div>
            </div>
            <div class="col-lg-8 col">
              <div class="text-muted text-center">

                <div v-if="true" data-aos="flip-right" data-aos-duration="2000">
                  <b-button variant="light" pill size="sm me-2" class="d-none d-sm-inline">
                    00:00:00
                  </b-button>
                  <b-button variant="light" pill size="md me-2" @click="playStream" data-toggle="reload">
                    <i class="fs-1" :class="cssPlayButtonClass"></i>
                  </b-button>
                  <b-button variant="primary rounded-pill" size="md me-2" @click="muteStream">
                    <i :class="cssMuteButtonClass"></i>
                  </b-button>
                  <b-button variant="primary" pill size="sm me-2" id="streamChatOpenButton" data-bs-toggle="offcanvas" data-bs-target="#offcanvasChatMessages" aria-controls="offcanvasChatMessages">
                    <i class="ri-chat-1-line"></i>
                    chat
                    <span v-if="newMessageCounter > 0" class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{ newMessageCounter }}</span>
                  </b-button>
                  <b-button variant="light" pill size="sm me-2" class="d-none d-sm-inline">
                    <i class="ri-group-line me-1"></i>
                    {{ listenersCounter }}
                  </b-button>
                  <Donate ref="pnlDonations" v-if="streamConfigAllowDonations" :session="this.$route.params.session" :episode="this.$route.params.id"></Donate>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-body">

      <Profiles ref="pnlProfiles" :videoContainerVisible="false"></Profiles>

      <div id="playerPanel" class="stream-video-player d-none mt-3">
        <video id='player' ref="player" preload='metadata' playsinline muted></video>

        <div class="mt-2" v-if="pinnedMessage !== null">
          <h5 class="card-title mb-1">
            <i class="ri-pushpin-line"></i>
            <button type="button" @click="profileAction('', 'unpin')" class="btn-close float-end fs-11" v-if="false"></button>
          </h5>
          <p class="card-text" v-html="getLinkifiedPinnedMessage()"></p>
        </div>

      </div>

      <Messages ref="pnlMessages" :imModerator="false" @send-message="sendMessage" @chat-open="chatOpen" :donate-allows="streamConfigAllowDonations" :chat-allows="streamConfigAllowChat" :session="this.$route.params.session" :episode="this.$route.params.id"></Messages>

    </div>
  </div>

</Layout>
</template>
