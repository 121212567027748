<script>
export default {
  data() {
    return {
      sum: 0,
      count: 0,
    };
  },
  props: {
    session: String,
    episode: String,
  },
  components: {
  },
  methods: {
    updateDonate(donations) {
      this.sum = donations.sum;
      this.count = donations.count;
    },
    
  },
};
</script>

<template>
  <b-button variant="light" pill size="sm me-2" class="d-none d-sm-inline">
    <i class="ri-hand-coin-line me-1"></i>
    {{ sum }}
  </b-button>
  <router-link :to="'/payments/donation/' + this.episode + '/' + this.session" class="btn btn-sm rounded-pill rainbow-button" target="_blank">
    Donate
  </router-link>
</template>
